import { useState, useEffect } from 'react'
import { Row, Button, Select } from 'antd'

const DispatchExample = (props) => {
  const [data, setData] = useState('')
  const [error, setError] = useState(null)
  const [isLoaded, setIsLoaded] = useState(false)
  const [powerflowCase, setPowerflowCase] = useState('')
  var powerflowOptions = []
  props.options.power_flow_case.map((option) =>
    powerflowOptions.push({label: option, value: option})
  )
  const dispatchExample = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/${props.path}&case_name=${powerflowCase}`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
      })
      const data = await response.json()
      props.onDispatchExample(data) // Pass the fetched data to the callback in App.
    } catch (error) {
      console.error('Error fetching data:', error)
    }
  }

  const handlePowerflowCase = (value) => {
    setPowerflowCase(value)
  }

  return (
    <div style={{display: 'flex', flexWrap: 'wrap', gap: '10px'}}>

      <Select placeholder="select an existing power flow case as comparison" options={powerflowOptions} onChange={handlePowerflowCase}/>
      <Button type="primary" onClick={dispatchExample}>
        Conduct Unit Dispatch
      </Button>
    </div>
  )
}

export default DispatchExample
