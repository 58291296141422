import { useState, useEffect } from 'react'
import Chart from './Chart'
import { useContext } from 'react';
import { Grid } from 'antd'
import { AppContext} from './AppContext';

const { useBreakpoint } = Grid;
const TimeSeries = (props) => {
  const screens = useBreakpoint();
  let plantArray = []
  if (Array.isArray(props.plantName)) {
    plantArray = props.plantName
  } else plantArray.push(props.plantName)

  const { savePlotData } = useContext(AppContext);
  const { plotData } = useContext(AppContext);
  
  const plot1Data = plotData && plotData[`${props.variableName}_${props.dates}_${plantArray.join('_')}`] ;
  const [data, setData] = useState(plot1Data? plot1Data: '' )
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [isLoaded, setIsLoaded] = useState(false)



  if(props.dates.length === 1) {

  var startdate = new Date(props.dates[0].split(',')[1])
    .toISOString()
    .slice(0, 10)
  var enddate = new Date(props.dates[0].split(',')[3])
    .toISOString()
    .slice(0, 10)
  } else if (props.dates.length === 2) {

    var startdate = new Date(props.dates[0].$d)
    .toISOString()
    .slice(0, 10)

    var enddate = new Date(props.dates[1].$d)
    .toISOString()
    .slice(0, 10)
  }


  
  useEffect(() => {
    if (!plot1Data) {
      fetch(
        `${process.env.REACT_APP_BACKEND_URL}/${props.path}&startdate=${startdate}&enddate=${enddate}`,
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(plantArray),
        }
      )
        .then((res) => res.json())
        .then(
          (result) => {
            setIsLoaded(true)
            setData(result)
            savePlotData(`${props.variableName}_${plantArray.join('_')}`, result);
          },
          (error) => {
            setIsLoaded(true)
            setError(error)
          }
        )
    } else {
      setData(plot1Data)
      setIsLoaded(true)
    }
  }, [data, props.plantName])

  if (error) {
    return <div>Error: {error.message}</div>
  } else if (!isLoaded) {
    return <div>Loading {props.variableLabel} plot...</div>
  } else if (data) {
    return (
      <div>
        <Chart width={screens.lg? 600: 475} height={300} variable={props.variableLabel} unit={props.variableUnit} timeseries={data} />
      </div>
    )
  }
  return <div />
}

export default TimeSeries
