import { useState, useEffect } from 'react'
import Plants from './Plants'
import dayjs from 'dayjs'
import {
  Row,
  Col,
  DatePicker,
  Form,
  Button,
  Space,
  Slider,
  Select,
  Alert,
  Layout,
  Grid
} from 'antd'
import { DownOutlined } from '@ant-design/icons'
import './HydrowireGUI/Hydrowires.css'
import SteadyStateDispatchDataTabs from './SteadyStateDispatchDataTabs'
import LoadModels from './LoadModels'
import DispatchAll from './DispatchAll'
import ExportAll from './ExportAll'
import FileSaver from 'file-saver'
import {
  STEADY_STATE_DISPATCH_PLANTS,
  STEADY_STATE_DISPATCH_PLANTS_DATA,
  LOAD_MODEL_BUTTON,
  MODEL_DATA,
  SLIDER_VALUE,
  YEAR,
  SEASON,
  STEADY_STATE,
  DISPATCH_ALL,
  AREA_DATA,
} from '../constants'
const { Header, Content, Sider } = Layout
const { useBreakpoint } = Grid;
const items = [
  {
    label: 'Spring',
    value: 'Spring',
  },
  {
    label: 'Summer',
    value: 'Summer',
  },
  {
    label: 'Fall',
    value: 'Fall',
  },
  {
    label: 'Winter',
    value: 'Winter',
  },
]
function SteadyStateDispatchTab() {
  const [formSubmitted, setFormSubmitted] = useState(false)
  const [area, setArea] = useState('')
  const [selectedDates, setSelectedDates] = useState([])
  const [plantSelected, setPlantSelected] = useState(false)
  const steadyStateDispatchPlantsStorage = localStorage.getItem(
    `${STEADY_STATE}-${STEADY_STATE_DISPATCH_PLANTS}`,
  )
  const [selectedPlants, setSelectedPlants] = useState(
    steadyStateDispatchPlantsStorage
      ? JSON.parse(steadyStateDispatchPlantsStorage)
      : [],
  )

  const steadyStateDispatchPlantsDataStorage = localStorage.getItem(
    `${STEADY_STATE}-${STEADY_STATE_DISPATCH_PLANTS_DATA}`,
  )
  const [plantsData, setPlantsData] = useState(
    steadyStateDispatchPlantsDataStorage
      ? JSON.parse(steadyStateDispatchPlantsDataStorage)
      : [],
  )

  const loadModelButtonClickedStorage = localStorage.getItem(
    `${STEADY_STATE}-${LOAD_MODEL_BUTTON}`,
  )
  const [loadModelButtonClicked, setLoadModelButtonClicked] = useState(
    loadModelButtonClickedStorage
      ? JSON.parse(loadModelButtonClickedStorage)
      : false,
  )
  const modelDataStorage = localStorage.getItem(`${STEADY_STATE}-${MODEL_DATA}`)
  const [modelData, setModelData] = useState(
    modelDataStorage ? JSON.parse(modelDataStorage) : null,
  )
  const areaDataStorage = localStorage.getItem(AREA_DATA)
  const [areaData, setAreaData] = useState(
    areaDataStorage ? JSON.parse(areaDataStorage) : [],
  )
  const yearStorage = localStorage.getItem(`${STEADY_STATE}-${YEAR}`)
  const [year, setYear] = useState(yearStorage ? JSON.parse(yearStorage) : '')

  const seasonStorage = localStorage.getItem(`${STEADY_STATE}-${SEASON}`)
  const [season, setSeason] = useState(
    seasonStorage
      ? items.find((entry) => entry.value === JSON.parse(seasonStorage)).value
      : items[0].value,
  )
  const sliderValueStorage = localStorage.getItem(
    `${STEADY_STATE}-${SLIDER_VALUE}`,
  )
  const [sliderValue, setSliderValue] = useState(
    sliderValueStorage ? JSON.parse(sliderValueStorage) : 80,
  )
  const dispatchAllStorage = localStorage.getItem(
    `${STEADY_STATE}-${DISPATCH_ALL}`,
  )
  const [dispatchAllData, setDispatchAllData] = useState(
    dispatchAllStorage ? JSON.parse(dispatchAllStorage) : null,
  )
  const [exportAll, setExportAll] = useState()
  const [form] = Form.useForm()
  const screens = useBreakpoint();
  let userSelectedPlants
  let plantCodes = []
  let plantsSelected = false
  const areaOptions = []
  if (
    plantsData !== undefined &&
    plantsData.length > 0 &&
    selectedPlants.length > 0
  ) {
    userSelectedPlants = selectedPlants.map((splant) =>
      Object.values(plantsData).filter((value) => value.plant_name === splant),
    )

    //Find the plant codes from plant name
    if (userSelectedPlants.length > 1) {
      plantCodes = userSelectedPlants.map((p) => p[0].project_name)
    } else {
      plantCodes = userSelectedPlants[0][0].project_name
    }
    plantsSelected = true
  } else plantsSelected = false
  const marks = {
    0: 0,
    //80: '80%',
    100: {
      label: <strong>Pmax</strong>,
    },
  }
  const formatter = (value) => `${value}%`

  const createTabs = () => {
    setLoadModelButtonClicked(true)
  }
  const onYearChange = (date, dateString) => {
    setYear(dateString)
    setLoadModelButtonClicked(false)
    localStorage.removeItem(`${STEADY_STATE}-${YEAR}`)
    localStorage.setItem(`${STEADY_STATE}-${YEAR}`, JSON.stringify(dateString))
  }
  const handleSeasonChange = (season) => {
    setSeason(season)
    setLoadModelButtonClicked(false)

    localStorage.removeItem(`${STEADY_STATE}-${SEASON}`)
    if (season) {
      localStorage.setItem(`${STEADY_STATE}-${SEASON}`, JSON.stringify(season))
    }
  }

  const handleAreaChange = (area) => {
    setArea(area)
  }

  const onChange = (e) => {
    console.log(`checked = ${e.target.checked}`)
  }

  const sliderChange = (v) => {
    setSliderValue(v)
    localStorage.removeItem(`${STEADY_STATE}-${SLIDER_VALUE}`)
    localStorage.setItem(`${STEADY_STATE}-${SLIDER_VALUE}`, JSON.stringify(v))
    setLoadModelButtonClicked(false)
  }

  const getPlants = (plants, projectData) => {
    setSelectedPlants(plants)
    localStorage.removeItem(`${STEADY_STATE}-${STEADY_STATE_DISPATCH_PLANTS}`)
    localStorage.setItem(
      `${STEADY_STATE}-${STEADY_STATE_DISPATCH_PLANTS}`,
      JSON.stringify(plants),
    )
    setPlantsData(projectData)
    localStorage.removeItem(
      `${STEADY_STATE}-${STEADY_STATE_DISPATCH_PLANTS_DATA}`,
    )
    localStorage.setItem(
      `${STEADY_STATE}-${STEADY_STATE_DISPATCH_PLANTS_DATA}`,
      JSON.stringify(projectData),
    )
    setLoadModelButtonClicked(false)
    setLoadModelButtonClicked(false)
  }

  const getModelData = (data) => {
    setModelData(data)
    if (data.length > 0) {
      localStorage.removeItem(`${STEADY_STATE}-${MODEL_DATA}`)
      localStorage.setItem(
        `${STEADY_STATE}-${MODEL_DATA}`,
        JSON.stringify(data),
      )

      localStorage.removeItem(`${STEADY_STATE}-${LOAD_MODEL_BUTTON}`)
      localStorage.setItem(
        `${STEADY_STATE}-${LOAD_MODEL_BUTTON}`,
        JSON.stringify(true),
      )
    }
  }

  const onFinish = (values) => {
    setFormSubmitted(false)

    form.setFieldsValue(values)

    if (plantSelected && setLoadModelButtonClicked) {
      setFormSubmitted(true)
    } else setFormSubmitted(false)
  }
  const pageReset = () => {
    Object.keys(localStorage).forEach((key) => {
      if (key.startsWith(STEADY_STATE)) {
        localStorage.removeItem(key)
      }
    })
    window.location.reload()
  }

  const onDispatchAllData = (data) => {
    setDispatchAllData(data)
    if (data.length > 0) {
      localStorage.removeItem(`${STEADY_STATE}-${DISPATCH_ALL}`)
      localStorage.setItem(
        `${STEADY_STATE}-${DISPATCH_ALL}`,
        JSON.stringify(data),
      )
    }
  }

  const onExportAll = (data) => {
    setExportAll(data)
    if (data[0]) {
      var blob = new Blob([new Uint8Array([0xef, 0xbb, 0xbf]), data[3].body], {
        type: 'text/plain;charset=utf-8',
      })
      FileSaver.saveAs(blob, data[2])
      setExportAll(data[1])
    } else setExportAll('No data found to export!')
  }
 if(areaData){

  const areaNames = areaData.reduce((acc, item) => {
   
    const existingArea = acc.find(obj => obj.area_name === item.area_name);
  
    if (existingArea) {
      
      existingArea.plant_names.push({ plant_name: item.plant_name });
    } else {
      
      acc.push({
        area_name: item.area_name,
        plant_names: [{ plant_name: item.plant_name }]
      });
    }
    return acc;
  }, []);
  areaNames.map((area) => {
   
    areaOptions.push({
      label: area.area_name,
      value: area.area_name,
    })
  })
 }
  return (
    <>
      <Layout>
        <Sider
        breakpoint="lg"
        collapsedWidth="0"
        onBreakpoint={(broken) => {
          console.log(broken);
        }}
        onCollapse={(collapsed, type) => {
          console.log(collapsed, type);
        }}

          width={400}
          style={{
            background: '#9fbad1',
          }}
        >
          <Form
            style={{ paddingLeft: '15px', paddingTop: '15px' }}
            form={form}
            name="control-hooks"
            onFinish={onFinish}
          >
            <h4 style={{ padding: '0px', margin: '10px' }}>
              Select Water Basin
            </h4>
            <Form.Item
              shouldUpdate
              name="plant"
              rules={[
                {
                  required: true,
                  message: 'Select water basin',
                },
              ]}
            >
              <Select
                allowClear
                style={{
                  width: '80%',
                  height: '100%',
                  overflowY: 'scroll',
                }}
                 placeholder="Please select Water Basin Area"
                onChange={handleAreaChange}
                options={areaOptions}
              ></Select>
            </Form.Item>

            <h4 style={{ padding: '0px', margin: '10px' }}>
              Apply Hydro Condition to Specfic Plant
            </h4>
            <Form.Item
              shouldUpdate
              name="plant"
              rules={[
                {
                  required: true,
                  message: 'Please select Plant',
                },
              ]}
            >
              <Plants
                selectPlants={getPlants}
                selectedPlants={selectedPlants}
                tooltipMessage={'Select one or more plants.'}
              />
            </Form.Item>

            <Row>
              <Col span={8}>
                <DatePicker
                  placeholder="Select Year"
                  onChange={onYearChange}
                  picker="year"
                  value={year ? dayjs(year) : ''}
                />
              </Col>
              <Col span={10} offset={1}>
                <Select
                  allowClear
                  style={{
                    width: '100%',
                    overflowY: 'scroll',
                  }}
                  placeholder="Select Season"
                  onChange={handleSeasonChange}
                  value={season}
                  options={items}
                >
                  <Space>
                    Select season
                    <DownOutlined />
                  </Space>
                </Select>
              </Col>
            </Row>
            <Form.Item>
              <h4 style={{ padding: '0px', margin: '10px' }}>
                General Efficiency Requirement Threshold
              </h4>
              <div style={{ width: '300px' }}>
                <Slider
                  value={sliderValue}
                  max={100}
                  min={0}
                  marks={marks}
                  onChange={sliderChange}
                  tooltip={{ formatter, placement: 'bottom', open: screens.lg? true: false }}
                />
              </div>
            </Form.Item>

            <Button
              onClick={createTabs}
              type="primary"
              disabled={dispatchAllData}
            >
              Load Dataset and Model
            </Button>
          </Form>

          <DispatchAll
            path={`dispatch_all/?general_threshold=${
              sliderValue / 100
            }&year=${year}&season=${season}&area_name=${area}`}
            plant={plantCodes}
            onDispatchAll={onDispatchAllData}
            button={loadModelButtonClicked}
          />
          <ExportAll
            path={`export_all/?area_name=${area}`}
            plant={plantCodes}
            onDispatchAll={onExportAll}
          />
          {exportAll ? (
            <div
              style={{
                paddingLeft: '10px',
                paddingRight: '10px',
                maxWidth: '100%',
              }}
            >
              <Alert message={exportAll} />{' '}
            </div>
          ) : (
            <></>
          )}
          <div style={{ paddingLeft: '15px' }}>
            <Button danger type="primary" style={{backgroundColor: '#df2c38'}} onClick={pageReset}>
              {' '}
              Reset{' '}
            </Button>
          </div>
          <br/>
        </Sider>
        <Layout
          style={{
            //padding: '0 24px 24px',
          }}
        >
          <Content
            style={{
              //padding: 24,
              margin: 0,
              minHeight: '100vh',
              background: '#d8ebfa',
              //borderRadius: borderRadiusLG,
            }}
          >
            <div
              style={{
                padding: 24,
                textAlign: 'center',
                background: '#d8ebfa',
                //borderRadius: borderRadiusLG,
              }}
            >
              <h1>Steady State Dispatch</h1>
              {loadModelButtonClicked && (
                <LoadModels
                  path={`regression_models/?year=${year}&season=${season}&area_name=${area}`}
                  plant={plantCodes}
                  onModeldata={getModelData}
                />
              )}
              {modelData && (
                <SteadyStateDispatchDataTabs
                  selectedPlants={selectedPlants}
                  plantsData={plantsData}
                  modelData={modelData}
                  sliderValue={sliderValue / 100}
                  season={season}
                  dispatchAll={false}
                />
              )}

              {dispatchAllData && (
                <SteadyStateDispatchDataTabs
                  selectedPlants={selectedPlants}
                  plantsData={plantsData}
                  modelData={dispatchAllData}
                  sliderValue={sliderValue / 100}
                  season={season}
                  dispatchAll={true}
                />
              )}
            </div>
          </Content>
        </Layout>
      </Layout>
    </>
  )
}

export default SteadyStateDispatchTab
