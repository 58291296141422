import React, { useState, useEffect, useMemo } from 'react'
import { GovBanner } from '@trussworks/react-uswds'
import { Button, Input, Form, Tabs, Space, Avatar, Popover } from 'antd'
import { CheckCircleOutlined, SyncOutlined } from '@ant-design/icons'
import StickyBox from 'react-sticky-box'
import { Outlet, useNavigate, useLocation } from 'react-router-dom'
import { Link } from 'react-router-dom'
import logo from '../../assets/pnnl-logo-abbreviated-white.png'
import { Layout, Row, Col, theme, Tooltip, Dropdown, Grid } from 'antd'
import { MenuOutlined } from '@ant-design/icons'
import HydatHome from './HydatHome'
import axios from 'axios'
import { jwtDecode } from 'jwt-decode'
import Cookies from 'js-cookie'
import '../../styles/plots.less'
import '../../styles/dropdown.less'
import PNNL_logo from '../../assets/PNNL Abbreviated Logo - WHITE (.svg).svg'
import hydat_logo from '../../assets/HyDAT.logo.BLACK.svg'
import './Hydrowires.css'
import TimeSeriesTab from '../TimeSeriesTab'
import SteadyStateDispatchTab from '../SteadyStateDispatchTab'
import Projects from '../Projects'
import SiteFooter from '../SiteFooter/SiteFooter'
import { useContext } from 'react'
import { AppContext } from '../AppContext'

import '@trussworks/react-uswds/lib/uswds.css'
import '@trussworks/react-uswds/lib/index.css'

const { TabPane } = Tabs
const { Header, Content, Footer } = Layout

const { useBreakpoint } = Grid
function withButtonContent(WrappedComponent, buttonName) {
  return function WrappedWithButtonContent(props) {
    return (
      <WrappedComponent
        {...props}
        isActive={props.activeButton === buttonName}
      />
    )
  }
}
const ButtonContentWithState1 = withButtonContent(TimeSeriesTab, 'Button1')
const ButtonContentWithState2 = withButtonContent(
  SteadyStateDispatchTab,
  'Button2',
)

function MemoizeComponent(Component) {
  return useMemo(() => <Component />, [])
}
function HydrowireGUI() {
  const [inputValue, setInputValue] = useState('hydrowire.db')
  const [buttonClicked, setButtonClicked] = useState(false)
  const [dbConnected, setDbConnected] = useState(false)
  const [timeseriesTab, setTimeseriesTab] = useState(false)
  const [steadyStateDispatchTab, setSteadyStateDispatchTab] = useState(false)

  const [selectedFile, setSelectedFile] = useState(null)
  const [isLoaded, setIsLoaded] = useState(true)
  const [databaseConnected, setDatabaseConnected] = useState(false)
  const [form] = Form.useForm()

  const [selectedTab, setSelectedTab] = useState('1')
  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const [decoded, setDecoded] = useState()
  const [open, setOpen] = useState(false)

  const { resetAppState } = useContext(AppContext)
  const { loginContext } = useContext(AppContext)
  const { logoutContext } = useContext(AppContext)
  const navigate = useNavigate()
  const location = useLocation()
  const screens = useBreakpoint()
  const hamburgerItems = [
    {
      label: <Link to="home"> Hy-DAT Home </Link>,
      key: '1',
    },
    {
      label: <Link to="timeseries"> Timeseries </Link>,
      key: '2',
    },
    {
      label: <Link to="steadystatedispatch"> Steady State Dispatch </Link>,
      key: '3',
    },
  ]
  
  const buttonStyle = (path) => (

    {
    
    backgroundColor: location.pathname === path ? '#ffffff' : '',
    fontWeight: '700px',
    fontSize: '20px',
    //borderColor: location.pathname === path ? '#ffffff' : '',
   color: location.pathname === path ? '#000000' : '',
    
  })



  const databaseConnection = (result) => {
    if (result.length > 0) setDatabaseConnected(true)
  }

  const handleOpenChange = (newOpen) => {
    setOpen(newOpen)
  }

  const logout = () => {
    resetAppState()
    var url = process.env.REACT_APP_POST_LOGOUT_URI
    var encodedUrl = encodeURIComponent(url)
    var token = Cookies.get('access-token')
    var client_id = process.env.REACT_APP_CLIENT_ID
    logoutContext()
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/auth/logout`)
      .then((response) => {
        const url = `${response.data}?post_logout_redirect_uri=${encodedUrl}&client_id=${client_id}`
        window.location = url
      })
    Cookies.remove('access-token')
    Cookies.remove('id-token')
    Cookies.remove('refresh-token')
    setIsLoggedIn(false)
    logoutContext()
  }

  const login = () => {
    if (!isLoggedIn) {
      axios
        .get(`${process.env.REACT_APP_BACKEND_URL}/auth/login-link`)
        .then((response) => {
          window.location = response.data
        })
    }
    //loginContext()
  }
  useEffect(() => {
    if (Cookies.get('access-token')) {
      setDecoded(jwtDecode(Cookies.get('access-token')))
      setIsLoggedIn(true)
      loginContext()
    }
  }, [])
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const sessionState = urlParams.get('session_state')
    const code = urlParams.get('code')

    console.log('Session state:', sessionState)
    console.log('Code:', code)
    if (sessionState && code) {
      const queryParams = new URLSearchParams({
        session_state: sessionState,
        code: code,
      })

      axios
        .get(
          `${process.env.REACT_APP_BACKEND_URL}/auth/callback?${queryParams}`,
        )
        .then((response) => {
          console.log(response)
          Cookies.set('access-token', response.data.access_token)
          Cookies.set('id-token', response.data.id_token)
          Cookies.set('refresh-token', response.data.refresh_token)
          setDecoded(jwtDecode(response.data.access_token))
          setIsLoggedIn(true)
          loginContext()
          navigate('/')
        })
        .catch(() => {
          navigate('/')
        })
    }
  }, [])

  const callTimeseries = () => {
    setSteadyStateDispatchTab(false)
    setTimeseriesTab(true)
  }

  const onReset = () => {
    resetAppState()
    window.location.reload()
  }

  const callSteadyStateDispatch = () => {
    setTimeseriesTab(false)
    setSteadyStateDispatchTab(true)
  }

  const handleFileUpload = (dbpath) => {
    setIsLoaded(false)
    const requestOptions = {
      method: 'POST',
      headers: {
        accept: 'application/json',
      },
    }

    fetch(
      `${process.env.REACT_APP_BACKEND_URL}/dbpath/${dbpath}`,
      requestOptions,
    )
      .then((response) => {
        if (response.status === 200) setDbConnected(true)
        setIsLoaded(true)
      })
      .catch((error) => {
        console.error(error)
        setIsLoaded(true)
      })
  }

  const onFinish = (values) => {
    setInputValue(values.dbpath)
    setButtonClicked(true)
    handleFileUpload(values.dbpath)
    form.setFieldsValue(values)
  }

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo)
  }

  const {
    token: { colorBgContainer },
  } = theme.useToken()

  const renderTabBar = (props, DefaultTabBar) => (
    <StickyBox
      offsetTop={0}
      offsetBottom={20}
      style={{
        zIndex: 1,
      }}
    >
      <DefaultTabBar
        {...props}
        style={{
          background: colorBgContainer,
        }}
      />
    </StickyBox>
  )

  return (
    <Layout>
      <GovBanner
        language="english"
        tld=".gov"
        aria-label="Official government website"
        background-color="#000000"
      />
      <Header
        style={{
          position: 'sticky',
          top: 0,
          zIndex: 1,
          width: '100%',
          height: 80,
          backgroundColor: '#000000',
        }}
      >
        <div>
          <Row>
            {databaseConnected && (
              <Dropdown
                menu={{
                  items: hamburgerItems,
                }}
                trigger={['click']}
                //overlayStyle={{ color: ScientiaColors['scientia-light-green'] }}
                /* dropdownRender={(menu) => (
            <div style={contentStyle}>
              {React.cloneElement(menu, {
                style: customStyles,
              })}
            </div>
          )}*/
              >
                <a onClick={(e) => e.preventDefault()}>
                  <Space className="hamburger-menu">
                    <MenuOutlined
                      style={{
                        marginTop: '20px',
                        marginRight: '10px',
                        padding: '10px',
                        fontSize: '24px',
                        color: '#ffffff',
                      }}
                    />
                  </Space>
                </a>
              </Dropdown>
            )}
            <Col flex="auto">
              <Space
                style={{
                  alignItems: 'center',
                  paddingTop: screens.lg ? '0px' : '15px',
                }}
              >
                <a
                  href="https://www.pnnl.gov/"
                  className="site-footer-link"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={PNNL_logo}
                    alt="PNNL Logo"
                    width={screens.lg ? '70px' : '50px'}
                    height={screens.lg ? '65px' : '45px'}
                  />
                </a>

                <Link to="/">
                  {/* <h2 style={{ margin: '0 0 0 0', color: '#fff' }}>Hy-DAT</h2> */}
                  <img
                    src={hydat_logo}
                    style={{ paddingTop: '10px' }}
                    alt="Hy-Dat Logo"
                    width={screens.lg ? '70px' : '50px'}
                    height={screens.lg ? '65px' : '45px'}
                  />
                </Link>
              </Space>
            </Col>
            <Col
              flex="auto"
              style={{ paddingTop: '10px', textAlign: 'center' }}
            >
              <div className="header-buttons">
                <Space>
                  {databaseConnected && (
                    <>
                      <Button
                        size="large"
                        type="text"
                        style={buttonStyle('/home')}
                      >
                        <Link
                          style={{ outline: 'none', boxShadow: 'none' }}
                          onFocus={(e) => (e.target.style.outline = 'none')}
                          to="home"
                        >

                          {' '}
                          Hy-DAT Home{' '}
                        </Link>
                      </Button>
                      <Button
                        size="large"
                        type="text"
                        style={buttonStyle('/timeseries')}
                      >
                        <Link
                          style={{ outline: 'none', boxShadow: 'none' }}
                          onFocus={(e) => (e.target.style.outline = 'none')}
                          to="timeseries"
                        >
 
                          {' '}
                          Timeseries{' '}
                        </Link>
                      </Button>

                      <Button
                        size="large"
                        type="text"
                        style={buttonStyle('/steadystatedispatch')} //style={{backgroundColor: '#DAD3BE', color: '#254336'}}
                      >
                        <Link
                          style={{ outline: 'none', boxShadow: 'none' }}
                          onFocus={(e) => (e.target.style.outline = 'none')}
                          to="steadystatedispatch"
                        >
 
                          {' '}
                          Steady State Dispatch{' '}
                        </Link>
                      </Button>
                      {/* <Menu onClick={handleTabChange} selectedKeys={[selectedTab]} mode="horizontal" items={items} /> */}
                    </>
                  )}
                </Space>
              </div>
            </Col>

            {!databaseConnected ? (
              isLoggedIn &&
              <>
                <Col flex="auto" style={{ paddingTop: '25px' }}>
                  <Tooltip title="Enter Database path and click connect to establish database connection">
                    <Form
                      form={form}
                      name="basic"
                      onFinish={onFinish}
                      onFinishFailed={onFinishFailed}
                      layout="inline"
                    >
                      <Form.Item
                        style={{
                          marginBottom: '0px',
                          width: screens.lg ? '200px' : '100px',
                        }}
                        name="dbpath"
                        rules={[
                          {
                            required: true,
                            message: 'Please enter database path',
                          },
                        ]}
                      >
                        <Input placeholder="app/data/Database.db" />
                      </Form.Item>
                      <Form.Item style={{ marginBottom: '0px' }}>
                        <Button
                          type="primary"
                          htmlType="submit"
                          style={{
                            backgroundColor: '#ffffff',
                            color: '#000000',
                          }}
                        >
                          Connect
                        </Button>
                      </Form.Item>
                    </Form>
                  </Tooltip>
                </Col>
                <Col flex="auto">
                  {buttonClicked && (
                    <p
                      style={{
                        margin: 0,
                        fontSize: '20px',
                        color: '#ffffff',
                        paddingTop: '10px',
                      }}
                    >
                      {' '}
                      Connecting...
                    </p>
                  )}
                  {dbConnected && <>{window.location.reload()}</>}
                </Col>
                <Projects path="projects" parentCallback={databaseConnection} />
              </>
            ) : (
              <Col
                flex="auto"
                style={{ alignItems: 'center', paddingTop: '10px' }}
              >
                <Tooltip title="Application is connected to database and is ready to use">
                  <div
                    style={{
                      //paddingLeft: '100px',
                      //paddingTop: '0',
                      //margin: '0 0 0 0',
                      fontSize: '20px',
                      color: '#68e381',
                    }}
                  >
                    {screens.lg ? 'Connected ' : ''}
                    <CheckCircleOutlined />
                  </div>
                </Tooltip>
              </Col>
            )}
            {databaseConnected && (
              <Col
                flex="auto"
                style={{ alignItems: 'center', paddingTop: '10px' }}
              >
                <Tooltip title="Use Reset to reset Application state and clear all data">
                  <Button
                    style={{
                      background: 'transparent',
                      border: 'none',
                      color: '#ffffff',
                      fontSize: '20px',
                    }}
                    onClick={onReset}
                  >
                    {screens.lg ? 'Reset' : ''}
                    <SyncOutlined />
                  </Button>
                </Tooltip>
              </Col>
            )}
            <Col flex="auto" style={{ paddingTop: '10px', color: '#ffffff' }}>
              {!isLoggedIn && (
                <Button
                  size="medium"
                  style={{ backgroundColor: '#ffffff', color: '#000000' }}
                  type="primary"
                  onClick={login}
                >
                  <Link to="/callback"> Login </Link>
                </Button>
              )}
              {isLoggedIn && (
                <>
                  <Popover
                    color={'#ffffff'}
                    content={
                      <>
                        <h3 style={{ color: '#000000' }}>{decoded.name}</h3>
                        <h4 style={{ color: '#000000' }}>{decoded.email}</h4>
                        <Button
                          style={{ color: '#ffffff' }}
                          size="large"
                          type="primary"
                          onClick={logout}
                        >
                          <Link to="/"> Logout </Link>
                        </Button>
                      </>
                    }
                    //title={decoded.name}
                    trigger="click"
                    open={open}
                    onOpenChange={handleOpenChange}
                  >
                    <Avatar
                      style={{ backgroundColor: '#ffffff', color: '#000000' }}
                    >
                      {decoded.name.charAt(0).toUpperCase()}
                    </Avatar>
                  </Popover>
                </>
              )}
            </Col>
          </Row>
        </div>
      </Header>
      <Content
        className="site-layout"
        style={{
          padding: '0 5px 0 10',
          backgroundColor: '#d8ebfa',
          overflow: 'auto',
        }}
      >
        <div className="content-div">
          {/*renderContent()*/}
          <Outlet />
        </div>
      </Content>
      <SiteFooter />
    </Layout>
  )
}
export default HydrowireGUI
